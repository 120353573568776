import { graphql } from 'msw'
import { Location, Organization } from '../@types'
const { URL_BASE } = process?.env ?? {
  URL_BASE: 'http://localhost:3000',
}

export const handlers = [
  graphql.query<{ locations: Location[] }>('getLocations', (req, res, ctx) => {
    ctx.status(200)
    ctx.set('access-control-allow-origin', URL_BASE)
    return res(
      ctx.data({
        locations: [
          {
            id: 30,
            name: 'Del Valle High School',
            organizationId: 4,
          },
        ],
      })
    )
  }),
  graphql.query<{ organizations: Organization[] }>(
    'getOrganizations',
    (req, res, ctx) => {
      ctx.status(200)
      ctx.set('access-control-allow-origin', URL_BASE)
      return res(
        ctx.data({
          organizations: [
            {
              id: 1,
              name: 'Foobar ISD',
              state: 'TX',
              slug: 'delvalleisd',
              is_active: true
            },
            {
              id: 2,
              name: 'Bazbar ISD',
              state: 'FL',
              slug: 'delvalleisd 2',
              is_active: true
            },
          ],
        })
      )
    }
  ),
  graphql.query<{ organizations: Omit<Organization, 'state'>[] }>(
    'getOrganizationBySlug',
    (req, res, ctx) => {
      ctx.status(200)
      ctx.set('access-control-allow-origin', URL_BASE)
      return res(
        ctx.data({
          organizations: [
            {
              slug: 'delvalleisd',
              id: 4,
              name: 'Del Valle ISD',
              is_active: true
            },
          ],
        })
      )
    }
  ),
]
export default handlers
