
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import 'intl-pluralrules'
import { useRef } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FlagsProvider } from 'flagged'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from '../hooks/auth'
import useGoogleAnalytics from '../hooks/useGoogleAnalytics'
import PendoAgent from '../components/bits/PendoAgent'
import useLanguagePreference from '../hooks/useLanguagePreference'

import '../styles/globals.css'
import { QueryParamsProvider } from '../hooks/useQueryParams'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../tests/mocks')
}

function MyApp({ Component, pageProps }: AppProps) {
  useGoogleAnalytics()
  useLanguagePreference()

  const queryClientRef = useRef<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 2,
        },
      },
    })
  )

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <title>Goodside Health</title>
      </Head>
      <FlagsProvider
        features={JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAGS ?? '{}')}
      >
        <QueryClientProvider client={queryClientRef.current}>
          <AuthProvider>
            <QueryParamsProvider>
              <Component {...pageProps} />
            </QueryParamsProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <PendoAgent apiKey={process.env.NEXT_PUBLIC_PENDO} />
      </FlagsProvider>
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  