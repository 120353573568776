import { rest } from 'msw'
import { user } from '../../models/user'
import { PasswordlessStart, User } from '../@types'

const {
  URL_BASE = 'http://localhost:3000',
  SIGN_IN_PHONE_NUMBER = '7373009016',
} = process?.env ?? ({} as any)

export const handlers = [
  rest.post<unknown, unknown, PasswordlessStart>(
    /\/passwordless\/start/,
    (req, res, ctx) => {
      ctx.status(200)
      ctx.set('access-control-allow-origin', URL_BASE)
      return res(
        ctx.json({
          _id: '60661c983824a2f357154197',
          phone_number: `+1${SIGN_IN_PHONE_NUMBER}`,
          phone_verified: false,
          request_language: 'en-US,en;q=0.9',
        })
      )
    }
  ),
  rest.post<unknown, unknown, User>(/\/oauth\/token/, (req, res, ctx) => {
    ctx.status(200)
    ctx.set('access-control-allow-origin', URL_BASE)
    return res(ctx.json(user))
  }),
]
export default handlers
