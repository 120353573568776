import { useEffect } from 'react'
import useCookie, { updateItem } from 'react-use-cookie'
import { useRouter } from 'next/router'

export default function useLanguagePreference(): [string, updateItem] {
  const router = useRouter()
  const [languagePreference, setLanguagePreference] = useCookie(
    'NEXT_LOCALE',
    router.locale
  )

  useEffect(() => {
    if (router.locale !== languagePreference)
      router.push(router.asPath, undefined, { locale: languagePreference })
    // See https://github.com/vercel/next.js/issues/18127:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagePreference])

  return [languagePreference, setLanguagePreference]
}
