import { rest } from 'msw'
import { Organization, Patient } from '../@types'

const { URL_BASE = 'http://localhost:3000' } = process?.env ?? ({} as any)

// https://patient.api.pond.dev/api/patients

export const handlers = [
  rest.get<unknown, unknown, Patient[]>(/\/api\/patients$/, (req, res, ctx) => {
    ctx.status(200)
    ctx.set('access-control-allow-origin', URL_BASE)
    return res(
      ctx.json([
        {
          pond_id: 'TESLOC1000',
          first_name: 'Tes*',
          last_name: 'Loc***',
          middle_name: '',
          organization_id: 4,
          location_id: 100,
          id_associated: false,
        },
        {
          pond_id: 'TESUNL1000',
          first_name: 'Test',
          last_name: 'Unlocked',
          middle_name: '',
          organization_id: 4,
          location_id: 100,
          id_associated: true,
        },
      ])
    )
  }),
  rest.post<unknown, unknown, Patient>(
    /\/api\/patients\/^\/+\/associate$/,
    (req, res, ctx) => {
      ctx.status(201)
      return res(
        ctx.json({
          pond_id: 'TESUNL1000',
          first_name: 'Test',
          last_name: 'Unlocked',
          middle_name: '',
          organization_id: 4,
          location_id: 100,
          id_associated: true,
        })
      )
    }
  ),
  rest.post<unknown, unknown, Patient>(
    /\/api\/registrations\/create$/,
    (req, res, ctx) => {
      ctx.set('access-control-allow-origin', URL_BASE)
      return res()
    }
  ),
  rest.post(/\/api\/registrations\/reconsent$/, (req, res, ctx) => {
    ctx.status(201)
    return res()
  }),
  rest.post<unknown, unknown, Organization>(
    /\/api\/organizations$/,
    (req, res, ctx) => {
      ctx.set('access-control-allow-origin', URL_BASE)
      return res(
        ctx.json({
          id: 4,
          name: 'Del Valle ISD',
          state: 'Texas',
          slug: 'delvalleisd',
          is_active: true,
        })
      )
    }
  ),
  rest.get<unknown, unknown, Organization>(
    /\/api\/organizations\/4/,
    (req, res, ctx) => {
      ctx.set('access-control-allow-origin', URL_BASE)
      return res(
        ctx.json({
          id: 4,
          name: 'Del Valle ISD',
          state: 'Texas',
          slug: 'delvalleisd',
          is_active: true,
        })
      )
    }
  ),
]
export default handlers
