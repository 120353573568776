import { MockedRequest } from 'msw'

import restHandlers from './rest'
import graphqlHandlers from './graphql'

export const onUnhandledRequest = (req: MockedRequest) => {
  const isAllowed =
    req.url.host === 'localhost:3000' || req.url.host === 'localhost'

  if (!isAllowed) {
    throw Error(`Unmocked request to ${req.url}`)
  }
}

export const handlers = [...restHandlers, ...graphqlHandlers]

export default handlers
