export const user = {
  access_token: 'foobar-access-token',
  id_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzbXN8MTIzNDU2Nzg5MCIsIm5hbWUiOiJGb29iYXIgVXNlciIsImh0dHBzOi8vcG9uZC5kZXYvdXNlciI6eyJwaG9uZU51bWJlciI6IisxMTIzNDU2Nzg5MCJ9LCJpYXQiOjE1MTYyMzkwMjJ9.gLyI4zusuS4CTDgdKg-Hp1sKbuDYkLZrXxH5KrFLKR8',
  expires_in: 60 * 1000,
  expiresAt: 60 * 1000,
  refresh_token: 'foobar-refresh-token',
  scope: 'foobar-scope',
  token_type: 'foobar-token-type',
}
