import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createContext, ReactNode, useContext } from 'react'

type TQueryParamsProvider = { children: ReactNode }

type TQueryParamsContext = {
  state: TQueryParams
  setState: Dispatch<SetStateAction<TQueryParams>>
}

type TQueryParams = {
  utm_source: string
  utm_medium: string
  utm_campaign: string
}

const QueryParamsContext = createContext({} as TQueryParamsContext)

export const QueryParamsProvider = ({ children }: TQueryParamsProvider) => {
  const { query, isReady, pathname } = useRouter()

  const [state, setState] = useState<TQueryParams>({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  })

  useEffect(() => {
    if (isReady && pathname === '/[organization]/welcome') {
      setState(() => {
        delete query.organization
        return query as TQueryParams
      })
    }
  }, [query, isReady, pathname, state])
  return (
    <QueryParamsContext.Provider value={{ state, setState }}>
      {children}
    </QueryParamsContext.Provider>
  )
}

export const useQueryParams = () => useContext(QueryParamsContext)
