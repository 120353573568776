import { useEffect } from 'react'
import useScript from '@charlietango/use-script'
import { useOrgId } from '../../hooks/useOrgId'

interface PendoAgentProps {
  apiKey: string
}

const enablePendo = process.env.NODE_ENV == 'production'

const PendoAgent = enablePendo
  ? ({ apiKey }: PendoAgentProps) => {
      const [isReady] = useScript(
        `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`
      )

      const { orgId } = useOrgId({ shouldRedirect: false })

      useEffect(() => {
        if (isReady) {
          const options: pendo.InitOptions = {}

          if (orgId) {
            options.account = {
              id:
                process.env.NODE_ENV === 'development' ? `DEV_${orgId}` : orgId,
            }
          }

          pendo.initialize(options)
        }
      }, [isReady, orgId])

      return null
    }
  : () => null

export default PendoAgent
