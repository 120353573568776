let mswInterface: any = null
if (typeof window === 'undefined') {
  const { server } = require('./server')
  server.listen()
  mswInterface = server
} else {
  const { worker } = require('./browser')
  worker.start()
  mswInterface = worker
}
console.info('** msw active, unregistered requests disabled')
export const msw = mswInterface
