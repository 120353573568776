import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { cookieOptions, getCookie, setCookie } from 'react-use-cookie'

export type UseOrgIdConfig = {
  shouldRedirect: boolean
}

export const useOrgId = (config: UseOrgIdConfig = { shouldRedirect: true }) => {
  const router = useRouter()
  const orgId = getCookie('orgId')

  const setOrgId = async (accessToken: string, orgId: string) => {
    setCookie('orgId', orgId)

    return fetch('/api/auth0/update', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        app_metadata: {
          orgId: orgId,
        },
      }),
    })
  }

  useEffect(() => {
    if ((!orgId || orgId === '') && router.isReady && config.shouldRedirect) {
      router.push(`/associate/org?returnTo=${router.asPath}`)
    }
    // `router` is intentionally not included here, as it's not stable:
    // See https://github.com/vercel/next.js/discussions/18522#discussioncomment-836362
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, config.shouldRedirect])

  return {
    orgId,
    setOrgId,
    resetExpiry: (secondsFromNow: number) => {
      const days = secondsFromNow / (60 * 60 * 24)
      setCookie('orgId', orgId, {
        days,
      } as cookieOptions)
    },
  }
}
